import { useEffect, useState } from 'react'
import { sortBy } from 'lodash'

import { GET_CMS_BANNERS } from '@graphql/queries/getCmsBanners'
import { useAwaitQuery } from '@headless/hooks'

export const useCmsBanner = () => {
  const fetchCmsBanners: any = useAwaitQuery(GET_CMS_BANNERS)
  const [bannerList, setBannerList] = useState<any[]>([])

  useEffect(() => {
    const getCmsBanners = async () => {
      const { data } = await fetchCmsBanners()
      const result: any[] = data?.CmsBanners ?? []
      const banners: any[] = sortBy(result, 'position')
      setBannerList([...banners])
    }

    getCmsBanners()
  }, [fetchCmsBanners])

  return {
    bannerList
  }
}
