import { gql, DocumentNode } from '@apollo/client'

export const GET_CMS_PRODUCTS: DocumentNode = gql`
  query getCmsProducts($width: Int, $height: Int) {
    CmsProducts(width: $width, height: $height) {
      content
      id
      imgurl
      link
      name
      price
      special_price
      type
      hover_image
      view_all_link
    }
  }
`
