import {
  Carousel,
  Image,
  Link,
  Tab,
  TabContext,
  TabList,
  TabPanel
} from '@headless/ui'
import { useCmsProduct } from '@hooks/HomePage'

import { StyledCmsProduct, StyledItem, StyledLink } from './styled'

const CmsProduct = () => {
  const { bestProducts, newProducts, isMobile, value, handleChange } =
    useCmsProduct()
  // eslint-disable-next-line no-bitwise

  if (
    ((!bestProducts || bestProducts.length === 0) && !newProducts) ||
    newProducts.length === 0
  ) {
    return null
  }

  const bestLink: string = 'earrings-gn/product_list_order/best_seller'

  const newLink: string = 'earrings-gn/product_list_order/new_arrivals'

  const ProductCarousel = ({ products }: any) => {
    // 计算折扣力度
    const calculateDiscount = (special_price: string, price: string) => {
      const special_price__num: number = parseInt(special_price.slice(1), 10)
      const price__num: number = parseInt(price.slice(1), 10)
      const discount = Math.round(
        ((price__num - special_price__num) / price__num) * 100
      )

      return `${special_price}(${discount}% off)`
    }

    return (
      <Carousel
        slidesToShow={5}
        dots={false}
        arrows={!isMobile}
        nextArrowIcon={() => <i className="icon icon-next" />}
        prevArrowIcon={() => <i className="icon icon-prev" />}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          }
        ]}
      >
        {products.map((product: any) => {
          return (
            <StyledItem key={product.id}>
              <Link href={`/${product.link}`} title={product.name}>
                <span className="product__image--container">
                  <picture
                    className="product__image--wrapper"
                    style={{ paddingBottom: `${(321 / 237) * 100}%` }}
                  >
                    <Image
                      className="product__image--photo"
                      src={product.imgurl}
                      alt={product.name}
                    />
                    <Image
                      className="product__image--photo product__image--photo--hover"
                      src={product.hover_image}
                      alt={product.name}
                    />
                  </picture>
                </span>
              </Link>
              <p className="product__name">
                <Link href={`/${product.link}`} title={product.name}>
                  <span dangerouslySetInnerHTML={{ __html: product.name }} />
                </Link>
              </p>
              {product.special_price ? (
                <span className="product__price strike">{product.price}</span>
              ) : null}
              <span
                className={[
                  'product__price',
                  `${product.special_price ? 'has_special_price' : ''}`
                ].join(' ')}
              >
                {product.special_price
                  ? calculateDiscount(product.special_price, product.price)
                  : product.price}
              </span>
            </StyledItem>
          )
        })}
      </Carousel>
    )
  }

  return (
    <StyledCmsProduct>
      <TabContext value={value}>
        <TabList centered={!isMobile} onChange={handleChange}>
          <Tab label="Best Sellers" value="best-seller" />
          <Tab label="New Arrivals" value="new-arrival" />
        </TabList>
        <TabPanel value="best-seller">
          <StyledLink>
            <Link href={`/${bestLink}`} title="View All">
              <span>View All</span>
            </Link>
          </StyledLink>
          <ProductCarousel products={bestProducts} />
        </TabPanel>
        <TabPanel value="new-arrival">
          <StyledLink>
            <Link href={`/${newLink}`} title="View All">
              <span>View All</span>
            </Link>
          </StyledLink>
          <ProductCarousel products={newProducts} />
        </TabPanel>
      </TabContext>
    </StyledCmsProduct>
  )
}

export default CmsProduct
