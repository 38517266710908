import { gql, DocumentNode } from '@apollo/client'

export const GET_CMS_GALLERY: DocumentNode = gql`
  query getCmsGallery {
    CmsGalleries {
      imgurl
      link
      name
      price
    }
  }
`
