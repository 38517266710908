import { useEffect, useMemo, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { GET_CMS_PRODUCTS } from '@graphql/queries/getCmsProducts'
import { useAwaitQuery } from '@headless/hooks'

export const useCmsProduct = () => {
  const fetchCmsProducts: any = useAwaitQuery(GET_CMS_PRODUCTS)
  const isMobile: boolean = useMediaQuery('(max-width:767px)')
  const [productList, setProductList] = useState<any[]>([])
  const [value, setValue] = useState('best-seller')

  const bestProducts = useMemo(() => {
    return productList.filter((product: any) => product.type === 'best-seller')
  }, [productList])

  const newProducts = useMemo(() => {
    return productList.filter((product: any) => product.type === 'new-arrivals')
  }, [productList])

  const handleChange = (event: any, param: string) => {
    setValue(param)
  }

  useEffect(() => {
    const getCmsProducts = async () => {
      const { data } = await fetchCmsProducts({
        variables: {
          width: 345,
          height: 460
        }
      })
      const result: any[] = data?.CmsProducts ?? []
      setProductList([...result])
    }

    getCmsProducts()
  }, [fetchCmsProducts])

  return {
    bestProducts,
    newProducts,
    isMobile,
    value,
    handleChange
  }
}
