import { gql, DocumentNode } from '@apollo/client'

export const GET_CMS_BANNERS: DocumentNode = gql`
  query getCmsBanners {
    CmsBanners {
      id
      link
      m_pic
      p_pic
      position
      subtitle
      title
    }
  }
`
