import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { GET_CMS_GALLERY } from '@graphql/queries/getCmsGallery'
import { useAwaitQuery } from '@headless/hooks'

export const useCmsGallery = () => {
  const fetchCmsGallery: any = useAwaitQuery(GET_CMS_GALLERY)
  const storeConfig: any = useSelector((state: any) => state.app.storeConfig)
  const [productList, setProductList] = useState<any[]>([])

  const baseURL: string = useMemo(() => {
    return storeConfig?.secure_base_url ?? ''
  }, [storeConfig])

  useEffect(() => {
    const getCmsGallery = async () => {
      const { data } = await fetchCmsGallery()
      const result: any[] = data?.CmsGalleries ?? []
      setProductList([...result])
    }

    getCmsGallery()
  }, [fetchCmsGallery])

  return {
    baseURL,
    productList
  }
}
