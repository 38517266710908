import { FC } from 'react'

import TabContext, { TabContextProps } from '@mui/lab/TabContext'

export const HeadlessTabContext: FC<TabContextProps> = ({
  children,
  ...props
}) => {
  return <TabContext {...props}>{children}</TabContext>
}
