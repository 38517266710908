import { Carousel, Link, MediaLayout } from '@headless/ui'
import { useCmsBanner } from '@hooks/HomePage'

import { StyledCmsBanner } from './styled'

const CmsBanner = () => {
  const { bannerList } = useCmsBanner()

  return (
    <StyledCmsBanner>
      {bannerList.length > 0 && (
        <Carousel slidesToShow={1} arrows={false} autoplay infinite lazyLoad>
          {bannerList.map((banner: any) => {
            const href: string = banner.link.startsWith('/')
              ? banner.link
              : `/${banner.link}`
            return (
              <Link
                key={`banner__ids__${banner.id}`}
                href={href}
                title={banner.title}
                target="_blank"
                rel="noopener"
              >
                <MediaLayout media="(max-width:640px)">
                  <picture>
                    <img src={banner.m_pic} alt={banner.title} />
                  </picture>
                </MediaLayout>
                <MediaLayout media="(min-width:640px)">
                  <picture>
                    <img src={banner.p_pic} alt={banner.title} />
                  </picture>
                </MediaLayout>
              </Link>
            )
          })}
        </Carousel>
      )}
    </StyledCmsBanner>
  )
}

export default CmsBanner
