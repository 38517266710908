import styled from '@emotion/styled'

export const StyledCmsProduct = styled.div`
  width: calc(100% - 7.5rem);
  margin: 0 auto;

  .MuiTabs-root {
    margin: 2rem 0;

    .MuiButtonBase-root {
      color: #999;
      font-size: 2.25rem;
      font-family: 'serif';
      cursor: pointer;
      text-transform: capitalize;
    }

    .Mui-selected {
      color: #222;
    }

    .MuiTabs-indicator {
      height: 4px;
      background-color: #f2e0d6;
    }
  }

  .slick-prev {
    top: -2.3rem;
    right: 2.8rem !important;
    left: unset !important;
    transform: unset;
  }

  .slick-next {
    top: -2.3rem;
    right: 1rem !important;
    transform: unset;
  }

  .icon {
    display: block;
    width: 8px;
    height: 24px;
    background: url('/images/nav.png') no-repeat;
    background-position: center;
    background-size: 100%;
    transition: all 0.3s;

    &-prev {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    .MuiTabPanel-root {
      padding: 0;
    }

    .MuiTabs-root {
      .MuiButtonBase-root {
        padding: 0.75rem 0;
        font-size: 1.5rem;
        font-family: 'serif';
        line-height: 1;
      }
    }

    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }

    .slick-dots {
      display: none !important;
    }
  }
`

export const StyledItem = styled.div`
  padding-right: 0.9375rem;
  text-align: center;

  > .MuiLink-root {
    position: relative;
    @media screen and (min-width: 767px) {
      &:hover {
        .product__image {
          &--photo {
            &--hover {
              opacity: 1;
              visibility: visible;
              transform: translateY(1);
            }
          }
        }
      }
    }
  }

  .product {
    &__name {
      margin-top: 0.625rem;

      > a {
        color: #212529;
        font-size: 1.125rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-decoration: none;

        &:hover {
          color: #dbb19f;
        }
      }
    }

    &__price {
      color: #222;
      font-size: 1.125rem;
      &.strike {
        text-decoration-line: line-through;
        color: #999999;
        margin-right: 15px;
      }

      &.has_special_price {
        color: #d22c2c;
      }
    }
  }

  .product__image {
    &--container {
      display: block;
      max-width: 100%;
    }

    &--wrapper {
      display: block;
      height: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &--photo {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      width: auto;

      &--hover {
        opacity: 0;
        visibility: hidden;
        transition: all 500ms;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-right: 1.375rem;
  }
`

export const StyledLink = styled.div`
  margin-bottom: 0.75rem;
  text-align: right;

  .MuiLink-root {
    display: block;
    margin-right: 4.6rem;
    color: #52575d;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;

    &:hover {
      color: #dbb19f;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
